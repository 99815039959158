import { Box } from '@mui/material'
import Image from 'next/image'
import { FunctionComponent, useEffect, useState } from 'react'
import { useIsMounted } from 'usehooks-ts'

const DURATION = 1500

interface Props {
  topImage: any
  bottomImage: any
  isSwitched: boolean
}

const CrossfadeImage: FunctionComponent<Props> = ({
  topImage,
  bottomImage,
  isSwitched,
}) => {
  const [randomDelay, setRandomDelay] = useState<number | null>(null)
  const isMounted = useIsMounted()

  const topSrc = topImage.file.url
  const topAlt = topImage.title
  const bottomSrc = bottomImage.file.url
  const bottomAlt = bottomImage.title

  useEffect(() => {
    // Wait and do this on the client to not cause client/server mismatch error
    if (isMounted() && !randomDelay) {
      setRandomDelay(Math.floor(Math.random() * 3) + 1)
    }
  }, [isMounted, randomDelay])

  const secondsDelay = randomDelay ? randomDelay * 1000 : 0
  const imageStyle = {
    opacity: isSwitched ? 0.99 : 0,
    transition: `opacity ${DURATION / 1000}s ease ${secondsDelay / 1000}s`,
  }

  return (
    <Box sx={{ position: 'relative', height: 1 }}>
      {topImage && <Image src={'https:' + topSrc} alt={topAlt} fill />}
      {bottomImage && (
        <Image
          src={'https:' + bottomSrc}
          alt={bottomAlt}
          fill
          style={imageStyle}
        />
      )}
    </Box>
  )
}

export default CrossfadeImage
