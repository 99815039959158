import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import CrossfadeImage from './CrossfadeImage'
import ScrollAnimate from './ScrollAnimate'

const gridStyles = {
  display: 'grid',
  gridTemplateAreas: {
    xs: `
      "one two"
      "three three"
    `,
    sm: `
      "one two four six"
      "three three four six"
      "three three five seven"
    `,
    lg: `
      "one one two two four four six six six nine nine nine"
      "three three three three four four six six six nine nine nine"
      "three three three three five five seven seven eight eight ten ten"
    `,
  },
  gridTemplateRows: {
    xs: '174px 301px',
    sm: '173px 123px 175px',
    xl: '266px 190px 345px',
  },
  gridGap: { xs: 2, sm: 4, xl: 8 },
  '& > div:nth-of-type(1)': {
    gridArea: 'one',
  },
  '& > div:nth-of-type(2)': {
    gridArea: 'two',
  },
  '& > div:nth-of-type(3)': {
    gridArea: 'three',
  },
  '& > div:nth-of-type(4)': {
    display: { xs: 'none', sm: 'block' },
    gridArea: 'four',
  },
  '& > div:nth-of-type(5)': {
    display: { xs: 'none', sm: 'block' },
    gridArea: 'five',
  },
  '& > div:nth-of-type(6)': {
    display: { xs: 'none', sm: 'block' },
    gridArea: 'six',
  },
  '& > div:nth-of-type(7)': {
    display: { xs: 'none', sm: 'block' },
    gridArea: 'seven',
  },
  '& > div:nth-of-type(8)': {
    display: { xs: 'none', lg: 'block' },
    gridArea: 'eight',
  },
  '& > div:nth-of-type(9)': {
    display: { xs: 'none', lg: 'block' },
    gridArea: 'nine',
  },
  '& > div:nth-of-type(10)': {
    display: { xs: 'none', lg: 'block' },
    gridArea: 'ten',
  },
}

const ImageGrid = ({ list, altList }) => {
  const [isSwitched, setisSwitched] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      setisSwitched(!isSwitched)
    }, 5000)
    return () => clearInterval(timer)
  })

  const imagesList = [...list]
  const altImages = [...altList]

  return (
    <ScrollAnimate>
      <Box sx={gridStyles}>
        {imagesList.map((img, index) => (
          <Box
            sx={{
              '& img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                objectPosition: 'center top',
              },
            }}
            key={img.sys.id}
          >
            <CrossfadeImage
              isSwitched={isSwitched}
              topImage={img.fields}
              bottomImage={altImages[index].fields}
            />
          </Box>
        ))}
      </Box>
    </ScrollAnimate>
  )
}

export default ImageGrid
